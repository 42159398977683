.swiper-pagination {
  position: relative;
}

.swiper-pagination-bullet {
  opacity   : 1;
  border    : 1px solid $body-text-color;
}

.swiper-pagination-bullet-active {
  background: $body-text-color;
  border    : 1px solid $body-text-color;
}

.swiper-button-next,
.swiper-button-prev {
  background-image: url("../img/svg/arrow-right.svg");
}

.swiper-button-prev {
  transform: rotate(180deg);
}
