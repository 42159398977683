header {
  position: fixed;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid $black;
  top: 0;
  left: 0;
  z-index: 10;
  transition: border 0.4s ease-in-out;

  form {
    position: relative;
    margin: 10px auto;
    width: 30px;
    height: 16px;

    .bg {
      position: absolute;
      width: 31px;
      height: 18px;
      box-shadow: 0 -12px 15px rgba(0, 0, 0, 0.02) inset, 0px 0px 0px 1px $black inset;
      border-radius: 11px;
      background: $black;
      -webkit-transition: background 0.3s, box-shadow 0.4s;
      -moz-transition: background 0.3s, box-shadow 0.4s;
      transition: background 0.3s, box-shadow 0.4s;
    }

    input[type="checkbox"]:checked ~ #bg2 {
      box-shadow: 0 -12px 15px rgba(0, 0, 0, 0.02) inset, 0px 0px 0px 13px $black inset;
      background: $black;
    }

    input[type="checkbox"]:checked ~ .button {
      left: 14px;
    }

    input {
      position: absolute;
      top: -2;
      left: -2;
      opacity: 0;
      width: 30px;
      height: 16px;
      margin: 0;
      padding: 0;
      cursor: pointer;
      z-index: 20;
    }

    .button {
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid;
      box-shadow: 0 -12px 15px rgba(0, 0, 0, 0.02) inset, 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
      background: $offwhite;
      border-radius: 10px;
      position: absolute;
      margin: 1px;
      z-index: 19;
      -webkit-transition: left 0.3s;
      -moz-transition: left 0.3s;
      transition: left 0.3s;
    }
  }

  .container-fluid,
  .row {
    height: 100%;
  }
}

header {
  &.active-background {
    // border-bottom: 1px solid black;
  }
}

.burger-wrap {
  width: 45px;
  height: 40px;
  border-right: 1px solid $black;
  display: flex;
  flex-direction: column;
  justify-content: center;

  #burger-menu {
    position: absolute;
    left: 18px;
    width: 20px;
    height: 20px;
    z-index: 99;
    cursor: pointer;

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $black;
      transition: background-color 0.25s, transform 0.25s;
      -webkit-transition: background-color 0.25s, -webkit-transform 0.25s;

      &:nth-of-type(1) {
        top: 25%;
      }

      &:nth-of-type(4) {
        top: 75%;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        top: 50%;
      }
    }

    &.open {
      span {
        background-color: $black;

        &:nth-of-type(1),
        &:nth-of-type(4) {
          transform: scaleX(0);
          -webkit-transform: scaleX(0);
        }

        &:nth-of-type(2) {
          transform: rotate(-45deg);
          -webkit-transform: scaleX(-45deg);
        }
        &:nth-of-type(3) {
          transform: rotate(45deg);
          -webkit-transform: scaleX(45deg);
        }
      }
    }
  }
}

.header-menu {
  display: none;
  width: 400px;
  height: calc(100% - 40px);
  position: fixed;
  top: 40px;
  left: 0;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  background: $offwhite;
  border-right: 1px solid $black;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}

body.dark_mode {
  header {
    border-bottom: 1px solid $offwhite;
    form {
      .bg {
        box-shadow: 0 -12px 15px rgba(255, 255, 255, 0.02) inset, 0px 0px 0px 1px $black inset;
        background: $black;
      }

      input[type="checkbox"]:checked ~ #bg2 {
        box-shadow: 0 -12px 15px rgba(255, 255, 255, 0.02) inset, 0px 0px 0px 13px $offwhite inset;
        background: $offwhite;
      }

      .button {
        box-shadow: 0 -12px 15px rgba(255, 255, 255, 0.02) inset, 0px 2px 4px 0px rgba(255, 255, 255, 0.2);
        background: $black;
      }
    }
  }

  .header-menu {
    background: $black;
    border-right: 1px solid $offwhite;
  }
  .burger-wrap {
    border-right: 1px solid $offwhite;
    #burger-menu {
      span {
        background-color: $offwhite;
      }

      &.open {
        span {
          background-color: $offwhite;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
}
@include media-breakpoint-down(sm) {
  header {
    width: 100%;
    .header-menu {
      display: none;
      width: 100%;
    }
  }
}
