$body-background-color: #f4f3f1;
$body-text-color: #003047;
$mobile-nav-bg: #003047;
$placeholder-color: #003047;

$bezier: cubic-bezier(0.445, 0.5, 0.55, 0.95);

// Typography Options
$font-body: DecimaMonoLt;

%type-body {
  font-family: $font-body;
  font-weight: normal;
  font-style: normal;
}