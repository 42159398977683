/* Global
   Global styles that may be added to all pages.
*/

/* -----  NOTES: 
   To find something of the index CTRL+F -> " i. "
   add a number after that to find exact title ex. " i. 4. "

* Anagrama 2020
* https://www.anagrama.com/
* ----------
* Carlos Gomez 2020
* https://www.gomezcarlos.com/
*/

/* ===== [ I.NDEX ] ======
 * i. 1. Variables
 * i. 2. Static
 * i. 3. General
 * i. 4. Sections
 */

/* i. 1. Variables */

$black: #000;
$offwhite: #fff7f5;

/* End: Variables */
/* i. 2. Static */

/* End: Static */
/* i. 3. General */

body,
html {
  @extend %type-body;
  @extend %font-smoothing;
  color: $black;
  height: 100%;
  width: auto;
  background-color: $offwhite;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    font-size: 15px;
    line-height: 20px;
  }
  @include media-breakpoint-down(lg) {
    font-size: 18px;
    line-height: 24px;
  }

  &.dark_mode {
    background-color: $offwhite;
  }
}

main {
  opacity: 0;
}

.page-home,
.page-three {
  height: 100%;
  width: 100%;
  .section {
    height: 100%;
    position: relative;
  }
}

.img-wrap {
  height: 262px;
  width: 354px;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    mix-blend-mode: multiply;
  }
}

figure {
  &.absolute-caption {
    position: relative;

    figcaption {
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.2s ease;
      padding: 18px 20px;
      user-select: none;
    }

    &:hover {
      img {
        opacity: 0;
      }
      figcaption {
        opacity: 1;
      }
    }
  }
}

.draggable {
  touch-action: none;
}

.window {
  height: auto;
  width: 354px;
  min-height: 140px;
  border: 1px solid $black;
  background-color: $offwhite;
  display: flex;
  flex-direction: column;
  position: absolute;

  &.with-image {
    width: auto;
  }

  &-header {
    display: flex;
    flex-direction: column;
    height: 30px;
    width: calc(100% - 2px);
    margin: 0 auto;
    border-bottom: 1px solid $black;

    .button_close {
      display: flex;
      height: 30px;

      img{
        width: 15px;
      }
      .white {
        display: none;
      }
    }
  }

  &-control {
    justify-content: flex-end;
    .close {
      padding: 0px 10px;
      user-select: none;
    }
  }

  &-content {
    padding: 18px 35px;
    user-select: none;
  }

  &.one {
    z-index: 09;
    left: 128px;
    top: 81px;
  }
  &.two {
    z-index: 08;
    left: 205px;
    top: 152px;
  }
  &.three {
    z-index: 07;
    left: 318px;
    top: 223px;
  }
  &.four {
    z-index: 06;
    left: 600px;
    top: 405px;
  }
  &.five {
    z-index: 05;
    left: 718px;
    top: 476px;
  }

  &:hover {
    z-index: 100;
  }
}

.page-two {
  .window {
    &.one {
      z-index: 09;
      left: 30.5%;
      top: 30.5%;
    }
    &.two {
      z-index: 08;
      left: 31.5%;
      top: 31.5%;
    }
    &.three {
      z-index: 07;
      left: 32.5%;
      top: 32.5%;
    }
    &.four {
      z-index: 06;
      left: 33.5%;
      top: 33.5%;
    }
    &.five {
      z-index: 05;
      left: 34.5%;
      top: 34.5%;
    }
  }
}

.page-three {
  .window {
    &.one {
      z-index: 6;
      left: 6.5%;
      top: 10.5%;
    }
    &.two {
      z-index: 7;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &.three {
      z-index: 8;
      right: 6.5%;
      top: 52.5%;
      left: auto;
    }
    &.four {
      z-index: 9;
      left: 11%;
      top: 62%;
    }
    &:hover {
      z-index: 100;
    }
  }
}

body,
header {
  transition: background-color 0.4s ease, color 0.4s ease;
}

body.dark_mode {
  background-color: $black;
  color: $offwhite;
  .window {
    border: 1px solid $offwhite;
    background-color: $black;
    &-header {
      border-bottom: 1px solid $offwhite;

      .button_close {
        opacity: 1;
        .white {
          display: inline;
          opacity: 1;
        }
        .black {
          display: none;
        }
      }
    }
  }
  .img-wrap {
    img {
      mix-blend-mode: unset;
    }
  }
}
/* End: Static */
/* i. 4. ___ */

@include media-breakpoint-down(md) {
  .window {
    width: 304px;

    &.one {
      left: 64px;
      top: 90px;
    }
    &.two {
      left: 102px;
      top: 175px;
    }
    &.three {
      left: 209px;
      top: 260px;
    }
    &.four {
      left: 300px;
      top: 425px;
    }
    &.five {
      left: 400px;
      top: 505px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .window {
    width: 304px;

    &.one {
      left: 10px;
      top: 41px;
    }
    &.two {
      left: 60px;
      top: 142px;
    }
    &.three {
      left: 10px;
      top: 243px;
    }
    &.four {
      left: 60px;
      top: 335px;
    }
    &.five {
      left: 10px;
      top: 436px;
    }
  }
  .page-two {
    .window {
      &.one {
        left: 7.5%;
        top: 34%;
      }
      &.two {
        left: 10.5%;
        top: 37%;
      }
      &.three {
        left: 13.5%;
        top: 40%;
      }
      &.four {
        left: 16.5%;
        top: 43%;
      }
      &.five {
        left: 18.5%;
        top: 46%;
      }
    }
  }

  .page-three {
    .window {
      &.one {
        z-index: 8;
        left: 50%;
        top: 1.5%;
        transform: translateX(-50%);

      }
      &.two {
        z-index: 7;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.three {
        z-index: 6;
        right: 50%;
        top: 50%;
        left: auto;
        transform: translateX(50%);
      }
      &.four {
        z-index: 9;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        z-index: 100;
      }
    }
  }
}
