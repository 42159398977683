// Frameworks
@import 'fonts/fonts';
@import 'base/normalize';
@import 'vendor/bootstrap/bootstrap';
@import 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/css/swiper.css';
@import 'https://unpkg.com/aos@2.3.1/dist/aos.css';

@import 'base/spacing';
@import 'base/fonts';
@import 'base/variables';
@import 'base/global';
@import 'base/colors';

// Import Components
@import 'components/header';
@import 'components/footer';
@import 'components/buttons';
@import 'components/slider';
@import 'components/text';
@import 'components/select';
@import 'components/menu';
@import 'components/loader';

// Import Layouts
@import 'layouts/home';
@import 'layouts/splash';
@import 'layouts/error';

// Plugins
@import 'plugins/utilities';
//@import 'plugins/dev';
