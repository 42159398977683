.body-text,
body,
p {
  @extend %type-body;

  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.link {
  cursor: pointer;
}
