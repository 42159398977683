

.mobile-btn {
  @extend %transition;
  cursor        : pointer;
  position      : fixed;
  z-index       : 999999;
  top           : 15px;
  left          : 16px;
  width         : 17px;
  height        : 13px;
  display       : flex;
  flex-direction: row;

  .nav-btn {
    height    : 1px;
    width     : 100%;
    background: $mobile-nav-bg;
    @extend %transition;

    &::after,
    &::before {
      @extend %transition;

      content   : ' ';
      display   : block;
      height    : 1px;
      width     : 100%;
      background: $mobile-nav-bg;
    }

    &::before {
      transform: translateY(6px);
      width    : 12px;
    }

    &::after {
      transform: translateY(12px);
    }
  }

  &.open {
    transform: translate(0,7px);

    .nav-btn {
      height: 0;

      &::before {
        width    : 100%;
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(135deg)translate(0,1px);

      }
    }
  }
}

.mobile-menu {
  @extend %transition;
  opacity : 0;
  z-index       : -99999;
  pointer-events: none;
  padding-top   : 44px;
  width         : 100%;
  height        : 100%;
  position      : fixed;
  background    : $body-background-color;
  overflow      : scroll;

  &.open {
    @extend %transition;
    opacity       : 1;
    z-index       : 99999;
    pointer-events: all;

    li.nav-item {
      animation: fade-in-top .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }
    @for $i from 1 to 10 {
      li.nav-item:nth-child(#{$i}) {
        animation-delay: $i * 0.135s;
      }
    }
  }
}

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform        : translateY(-50px);
    opacity          : 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform        : translateY(-50px);
    opacity          : 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
    opacity          : 1;
  }
}
