$turquoise: turquoise;
$pale-turq: paleturquoise;
$dark-blue: darkblue;

// White Color Variables
$off-white: #FAFAFA;
$white-dawn: #FAF6F4;
$white-wall: #F0F0F0;
$white-alto: rgba(216,216,216,0.25);

// Gray Color Varables
$gray-silver: #CACACA;
$gray-venus: #929092;
$gray-jumbo: #78777A;

// Black Color Variables
$black-mine: #3E3E3E;
$black-shaft: #1F1F1F;